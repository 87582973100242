ul, li {
    list-style-type: none;
}

.draggable-list-item {
    padding: 0 0;
    border-top: 1px solid transparent;
    
}

.draggable-list-item * {
    pointer-events: none;
}

.draggable-list-item.dragstart {
    opacity: 0.5;
}

.draggable-list-item.dragover {
    border-top-color: green;
}