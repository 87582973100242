.surveytitle{
    background-color: #F0AB00;
    width: 100%;
    
}

.surveybody{
    background-color: #b8e9fa;
    
}

/*
.stepItem:hover * {
    background: rgb(202, 199, 178);
}*/

.stepItem{
    padding: 4%;
    width: 100%;
    /*
    border-color: black;
    border-style: solid;
    */
}

.surveyFooter{
    
    background-color: #b8e9fa;
    width: 100%;
    padding-bottom: 3%;
}

.typostyle{
    font-size: 0.9em !important;
}