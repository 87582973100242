.grid{
    height: 100vh;
  } 
  
  .map-box{
    position: relative;
    height: 100%;
    z-index: 0 !important;
  }
  .data-box{
    position: absolute;
    top: 20vh;
    left: 10vw;
    
    background-color: rgb(216, 216, 216);
    width: 40%;
    height: 60%;
    z-index: 10000 !important;

    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    
  }

  .data-box-new{
    position: absolute;
    top: 1vh;
    left: 1vw;
    
    
    width: 29%;
    height: 60%;
    z-index: 10000 !important;

    /*
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;*/
    
  }
  .data-box-menu{
    position: absolute;
    top: 80vh;
    left: 80vw;
    
   
    z-index: 10000 !important;
  }

/*  Pruebas  */

.cardtitle {
  position: absolute;
    top: 17vh;
    left: 23vw;
    z-index: 10001 !important;
    background-color: rgb(255, 255, 255);
    width: 8%;
    /* height: 5%; */
    
}

.cardcontent {
  position: absolute;
    top: 20vh;
    left: 10vw;
    padding-left: 1%;
    padding-right: 5%;
    /* padding-top: -10%; */

    /* background-color: rgb(216, 216, 216); */
    background-color: transparent;
    width: 40%;
    height: 60%;
    z-index: 10000 !important;

    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    /* background-position: center center; */
    background-size: cover;
    color: white;
    font-size: 24px!important;
    display: flex;
    justify-content: center;
    align-items: center;

}

.buttonsdiv{
  position: absolute;
  
  bottom:   0;
  padding-right: 10%;
  padding-bottom: 1%;
}

.innerCardTitle{
  
  margin: 2%;
  border-color: rgb(229, 55, 245);
  border-width: 2px;
  
  border-style: solid;
  padding: 10px;
  text-align: center;
  
}